import { useEffect } from "react";

function useScrollTop() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);

  return null;
}

export default useScrollTop;
