import React from "react";
import { Button } from "components";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "constants/routes";

const Subscription = (props) => {
  const navigate = useNavigate();
  return (
    <div className="subscription" id="subscription">
      <div className="subscription-wrapper">
        <h1>Let's make you more money!</h1>
        <p>
          Schedule a free strategy consultation and let's see how a professional
          website could help you scale
        </p>
        <Button
          name="BOOK A FREE CALL"
          className="white"
          onClick={() => navigate(ROUTES.CONSULATION)}
        />
      </div>
    </div>
  );
};

export default Subscription;
