import React from "react";

const Banner = (props) => {
  return (
    <div className="banner_bg">
      <div className="shape1"></div>
      <div className="shape3"></div>
      <div className="container">
        <h1>{props.title}</h1>
        <p>{props.desc}</p>
        {props.link && (
          <a href={props.link} target="_blank" without rel="noreferrer">
            {props.link}
          </a>
        )}
      </div>
    </div>
  );
};

export default Banner;
