import React from "react";

const Button = (props) => {
  return (
    <button
      disabled={props.disabled || props.loading}
      style={props.style}
      type={props.type}
      className={`main_button ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading ? "..." : props.name}
    </button>
  );
};

export default Button;
