import React, { useEffect, useState } from "react";
import { CiMenuFries } from "react-icons/ci";
import { Button, Logo } from "components";
import * as ROUTES from "constants/routes";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import i18next from "i18next";

const Header = (props) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [showHeader, setShowHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const selected = localStorage.getItem("i18nextLng");

  const languages = [
    {
      id: "en",
      title: "EN",
      country: "English",
      // flag: en,
    },
    {
      id: "sq",
      title: "SQ",
      country: "Shqip",
      // flag: albania,
    },
  ];

  const currentLanguage = languages.find((lng) => lng.id === selected);
  const changeLanguage = (e) => i18next.changeLanguage(e.target.value);

  const NavLink = ({ to, link, onClick }) => (
    <Link to={to} className={to === pathname ? "active" : ""} onClick={onClick}>
      {props.translate(`nav.${link}`)}
    </Link>
  );

  return (
    <>
      <header className={showHeader ? "header fixed-header" : "header"}>
        <div className="container header_wrapper">
          <Logo />
          <div className="links">
            <NavLink to={ROUTES.HOME} link="home" />
            <NavLink to={ROUTES.SERVICES} link="services" />
            <NavLink to={ROUTES.HELP_FAQ} link="faq" />
            <NavLink to={ROUTES.PORTFOLIO} link="portfolio" />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <select onChange={(e) => changeLanguage(e)}>
              {languages?.map((lang, i) => (
                <option
                  key={i}
                  selected={currentLanguage?.id === lang?.id}
                  value={lang?.id}
                >
                  {lang?.title}
                </option>
              ))}
            </select>
            <Button
              name={props.translate(`buttons.contact`)}
              className="black"
              onClick={() => navigate(ROUTES.CONTACT)}
            />
          </div>

          <CiMenuFries size={35} className="menu" onClick={toggleMenu} />
        </div>
      </header>
      {isOpen && (
        <div className={`menu-container`}>
          <div className="menu">
            <div onClick={toggleMenu} className="close-button">
              <AiOutlineClose />
            </div>
            <div className="menu-items">
              <NavLink to={ROUTES.HOME} link="home" onClick={toggleMenu} />
              <NavLink
                to={ROUTES.SERVICES}
                link="services"
                onClick={toggleMenu}
              />
              <NavLink to={ROUTES.HELP_FAQ} link="faq" onClick={toggleMenu} />
              <NavLink
                to={ROUTES.PORTFOLIO}
                link="portfolio"
                onClick={toggleMenu}
              />
              <Button
                name={props.translate(`buttons.contact`)}
                className="black"
                onClick={() => navigate(ROUTES.CONTACT)}
              />
            </div>
          </div>
          <div className="backdrop" onClick={toggleMenu}></div>
        </div>
      )}
    </>
  );
};

export default Header;
