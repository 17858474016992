import React from "react";
import * as ROUTES from "constants/routes";
import { CiMenuFries } from "react-icons/ci";
import { Logo } from "components";

const FigmaHeader = () => {
  const emailAddress = "dardanllapashticaa@gmail.com";
  const links = [
    {
      link: "Projects",
      to: ROUTES.PROJECTS,
    },
    {
      link: "Contact",
      to: "/",
    },
  ];

  return (
    <div className="figma_header">
      <Logo fill="#fff" />
      <CiMenuFries size={35} className="menu" />
      <div className="figma_header_links">
        {links?.map((item, i) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={item.link === "Contact" ? `mailto:${emailAddress}` : item.to}
            key={i}
            style={{
              fontFamily: "KanitMedium",
              fontSize: 20,
              textDecoration: "none",
              color: "#f5f5f5",
            }}
          >
            {item.link}
          </a>
        ))}
      </div>
    </div>
  );
};

export default FigmaHeader;
