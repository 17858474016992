import React from "react";

const HeaderTitle = (props) => {
  return (
    <div className="header_title">
      <h1>{props.title}</h1>
      {props.desc && <p>{props.desc}</p>}
    </div>
  );
};

export default HeaderTitle;
