import { CHAT } from "assets/images";
import { Button } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";
import Tilt from "react-parallax-tilt";

const Guarantee = (props) => {
  const navigate = useNavigate();
  return (
    <section className="guarantee container" id="guarantee">
      <div className="guarantee-left">
        <h1>
          Don’t worry, there’s <strong>no risk</strong> on your side
        </h1>
        <div className="guarantee-left-flex">
          <p>
            Investing in a new website can be daunting, especially when it
            involves significant costs.
          </p>
          <p>
            That's why I offer a FREE design sample each month to 5 prospective
            clients.
          </p>
          <p>
            This way, you can experience my work firsthand without any financial
            commitment, ensuring you’re completely satisfied before making an
            investment.
          </p>
        </div>
        <Button
          name="I WANT A FREE DESIGN SAMPLE"
          className="black"
          onClick={() => navigate(props.ROUTES.CONSULATION)}
        />
      </div>

      <div className="guarantee-right">
        <Tilt
          transitionSpeed={3500}
          perspective={500}
          glareEnable={true}
          glareMaxOpacity={0.45}
          scale={1.02}
        >
          <img alt="" src={CHAT} style={{ borderRadius: 10 }} />
        </Tilt>
      </div>
    </section>
  );
};

export default Guarantee;
