import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blogs }) => {
  return (
    <>
      {blogs?.map((item, i) => (
        <Link to={item.to} className="wrapper-item" key={i}>
          <img src={item.img} alt="" />
          <div className="wrapper-item-content">
            <h3>{item.title}</h3>
            <p>{item.desc}</p>
          </div>
        </Link>
      ))}
    </>
  );
};

export default BlogCard;
