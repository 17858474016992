import React, { useState, useEffect } from "react";
import { GoArrowUp } from "react-icons/go";

const ScrollUpButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div
      className={`scroll-up-button ${showButton ? "show" : ""}`}
      onClick={scrollToTop}
    >
      <GoArrowUp />
    </div>
  );
};

export default ScrollUpButton;
