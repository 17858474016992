import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  useAnimationOnScroll,
  useScrollTop,
  useDocumentTitle,
  usePortfolio,
} from "hooks";
import * as PAGES from "pages";
import * as ROUTES from "constants/routes";
import cv from "assets/cv.pdf";
import projects from "assets/projects.pdf";
import team_projects from "assets/team-projects.pdf";
import { ScrollUpButton, Header, Footer, Subscription } from "components";
import { useTranslation } from "react-i18next";

const CV = () => <iframe title="CV" src={cv} width="100%" height="1000px" />;

const PROJECTS = () => (
  <iframe title="projects" src={projects} width="100%" height="1000px" />
);

const TEAM_PROJECTS = () => (
  <iframe
    title="team-projects"
    src={team_projects}
    width="100%"
    height="1000px"
  />
);

function App() {
  const location = window.location.pathname;
  useScrollTop();
  useDocumentTitle();
  useAnimationOnScroll();
  const { loading } = usePortfolio();
  const history = createBrowserHistory();
  const { t } = useTranslation();
  const isCVorProjectsRoute = [
    ROUTES.CV,
    ROUTES.PROJECTS,
    ROUTES.TEAM_PROJECTS,
    ROUTES.TESTIMONIAL,
  ].includes(location);

  const showLines = [ROUTES.TESTIMONIAL].includes(location);

  return (
    <>
      {loading ? (
        <PAGES.LOADER />
      ) : (
        <Router history={history}>
          {showLines && <div className="lines"></div>}
          {!isCVorProjectsRoute && <Header translate={t} />}
          {!isCVorProjectsRoute && <ScrollUpButton />}
          <Routes>
            <Route path={ROUTES.HOME} element={<PAGES.HOME />} />
            <Route path={ROUTES.CONSULATION} element={<PAGES.CONSULATION />} />
            <Route path={ROUTES.SERVICES} element={<PAGES.SERVICES />} />
            <Route path={ROUTES.PORTFOLIO} element={<PAGES.PORTFOLIO />} />
            <Route
              path={ROUTES.PORTFOLIO_DETAILS}
              element={<PAGES.PORTFOLIO_DETAILS />}
            />
            <Route path={ROUTES.HELP_FAQ} element={<PAGES.HELP_FAQ />} />
            <Route path={ROUTES.TESTIMONIAL} element={<PAGES.TESTIMONIAL />} />
            <Route path={ROUTES.CONTACT} element={<PAGES.CONTACT />} />
            <Route path={ROUTES.CV} element={<CV />} />
            <Route path={ROUTES.TEAM_PROJECTS} element={<TEAM_PROJECTS />} />
            <Route path={ROUTES.PROJECTS} element={<PROJECTS />} />
            <Route path={ROUTES.LOADER} element={<PAGES.LOADER />} />
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
          </Routes>
          {!isCVorProjectsRoute && <Subscription />}
          {!isCVorProjectsRoute && <Footer />}
        </Router>
      )}
    </>
  );
}

export default App;
