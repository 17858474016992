import React from "react";
import { Button, HeaderTitle, ProjectItem } from "components";

const Projects = (props) => {
  return (
    <section className="container">
      {props.ROUTES.PORTFOLIO !== window.location.pathname && (
        <HeaderTitle
          title={props.t("content.latestProjects")}
          desc={props.t("content.helpedBusinesses", { count: 5 })}
        />
      )}
      <ProjectItem data={props?.projects} show={4} />
      {props.ROUTES.PORTFOLIO !== window.location.pathname && (
        <div style={{ textAlign: "right" }}>
          <Button
            name={props.t("buttons.seeAll")}
            className="mt50 black"
            onClick={() => props.navigate(props?.ROUTES.PORTFOLIO)}
          />
        </div>
      )}
    </section>
  );
};

export default Projects;
