import React from "react";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { Logo } from "./Logo";

const Footer = () => {
  const year = new Date().getFullYear();

  const SocialLink = (props) => (
    <a href={props.to} rel="noopener noreferrer" target="_blank">
      {props.icon}
    </a>
  );
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="footer-wrapper">
          <Logo fill="#252525" />
          <p>All rights reserved © {year}</p>
          <div>
            <div className="footer-wrapper-socials">
              <SocialLink
                to="https://www.facebook.com/dardan.llapashtica.16"
                icon={<FiFacebook />}
              />
              <SocialLink
                to="https://github.com/dardan743"
                icon={<FaGithub />}
              />
              <SocialLink
                to="https://www.instagram.com/dardan_llapashtica"
                icon={<FaInstagram />}
              />
              <SocialLink
                to="https://www.linkedin.com/in/dardan-llapashtica"
                icon={<FaLinkedin />}
              />

              <SocialLink
                to="https://twitter.com/Dardan_ll"
                icon={<FaXTwitter />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
