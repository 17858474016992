import { BlogCard } from "components";
import React from "react";

const Blog = () => {
  const blogs = [
    {
      to: "/",
      img: "https://i1.wp.com/blog.alexdevero.com/wp-content/uploads/2018/12/react-native-expo-how-to-build-your-first-mobile-app.jpg",
      title: "How to deploy and test apps using expo",
      desc: "This is a simple explanation on how to build and submit apps using expo eas cli",
    },
  ];

  return (
    <section className="blog" id="blog">
      <div className="container">
        <h1>Blog</h1>
        <div className="blog-wrapper">
          <BlogCard blogs={blogs} />
        </div>
      </div>
    </section>
  );
};

export default Blog;
