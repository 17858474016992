import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "styles/styles.scss";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import "i18n";
import axios from "axios";
import { Loader } from "components";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={Loader()}>
      <App />
    </Suspense>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();
