import Rating from "@mui/material/Rating";
import React, { useEffect, useState } from "react";
import { HeaderTitle } from "components";
import axios from "axios";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    axios
      .get("/testimonials")
      .then((res) => {
        setTestimonials(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    testimonials.length > 0 && (
      <section className="testimonials" id="testimonials">
        <div className="container">
          <HeaderTitle title="Join satisfied clients" />
          <div className="testimonials-wrapper">
            {testimonials?.map((item, i) => {
              return (
                <div className="testimonials-wrapper-item sm:col-12" key={i}>
                  <Rating value={item.rate} readOnly />
                  <p className="testimonials-wrapper-item-desc">
                    {item.review}
                  </p>

                  <div className="testimonials-wrapper-item-profile">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item.img}`}
                      alt=""
                    />
                    <div className="proflie">
                      <p className="name">{item.reviewer}</p>
                      <p className="company">{item.company}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    )
  );
};

export default Testimonials;
