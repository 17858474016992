import React from "react";

const Preloader = () => {
  return (
    <div className="progress-loader">
      <div className="progress"></div>
    </div>
  );
};

export default Preloader;
