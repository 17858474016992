import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

function useAnimationOnScroll() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);
}

export default useAnimationOnScroll;
