import { Accordian, Button } from "components";
import React from "react";

const Faq = (props) => {
  return (
    <section className="faq container" id="faq">
      <h1>Frequently Asked Questions</h1>
      <div className="questions">
        {props.qa?.slice(0, 2)?.map((item, i) => {
          return (
            <Accordian key={i} title={item.question}>
              {item.answer}
            </Accordian>
          );
        })}
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          name="HELP & FAQ"
          className="mt50 black"
          onClick={() => props.navigate(props?.ROUTES.HELP_FAQ)}
        />
      </div>
    </section>
  );
};

export default Faq;
