import { HeaderTitle } from "components";
import React from "react";

const Process = (props) => {
  return (
    <section className="process" id="process">
      <div className="container">
        <div className="shape1"></div>
        <div className="shape2"></div>
        <div className="shape3"></div>
        <HeaderTitle
          title={props.t("content.process.title")}
          desc={props.t("content.process.subtitle")}
        />

        <div className="process-roadmap">
          <div className="roadmap-line-2"></div>
          <div className="roadmap-line"></div>

          <div className="process-roadmap-wrap">
            <div className="roadmap-cell">
              <div className="roadmap-time">DAY 1 - 7</div>
              <div className="roadmap-point">
                <div className="roadmap-point-inside"></div>
              </div>
              <p className="roadmap-item-description">
                You share the details. I research and create a wireframe.
              </p>
            </div>
            <div className="roadmap-cell">
              <div className="roadmap-time">DAY&nbsp; 7 - 21</div>
              <div className="roadmap-point">
                <div className="roadmap-point-inside"></div>
              </div>
              <p className="roadmap-item-description">
                Wireframe is transformed into engaging lead-getting design.
              </p>
            </div>
            <div className="roadmap-cell">
              <div className="roadmap-time">DAY&nbsp;21 - 28</div>
              <div className="roadmap-point">
                <div className="roadmap-point-inside"></div>
              </div>
              <p className="roadmap-item-description">
                Your website or app is quickly developed in code. Including
                integrations.
              </p>
            </div>
            <div className="roadmap-cell">
              <div className="roadmap-time">NEXT&nbsp;STEPS</div>
              <div className="roadmap-point">
                <div className="roadmap-point-inside"></div>
              </div>
              <p className="roadmap-item-description">
                SEO campaign to get new users to your brand new website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
