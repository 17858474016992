import React from "react";
import { Link } from "react-router-dom";

const ProjectItem = (props) => {
  return (
    <div className="projects-item">
      {props.data?.slice(0, props.show)?.map((project, i) => (
        <Link
          key={project?._id ? project?._id : i}
          onClick={() => project?.to && window.open(project.to, "_blank")}
        >
          <img src={`${process.env.REACT_APP_API_URL}/${project.img}`} alt="" />
          <div className="proflie">
            <p className="name">{project.name}</p>
            <p className="company">{project.desc}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ProjectItem;
