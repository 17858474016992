import { lazy } from "react";

export const HOME = lazy(() => import("./Home"));
export const CONSULATION = lazy(() => import("./Consulation"));
export const SERVICES = lazy(() => import("./Services"));
export const CONTACT = lazy(() => import("./Contact"));
export const PORTFOLIO = lazy(() => import("./Portfolio"));
export const PORTFOLIO_DETAILS = lazy(() => import("./ProjectDetail"));
export const HELP_FAQ = lazy(() => import("./Faq"));
export const TESTIMONIAL = lazy(() => import("./Testimonials"));
export const LOADER = lazy(() => import("../components/Loader"));
